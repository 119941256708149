import Img from "../assets/images/married.webp";

export function Married() {
  return (
    <>
      <div className="view_married">
        <div
          className="img_container_mar"
          style={{ backgroundImage: `url(${Img})`, backgroundPosition: "60% 90%" }}
        ></div>
        <h2>Tack för att ni var med och förevigade denna speciella dag</h2>
        <p>Kära vänner och familj,</p>
        <p>
          Vi vill rikta ett varmt och hjärtligt tack till er alla för att ni delade denna oförglömliga dag med oss. Era
          leenden, skratt och varma önskningar har gjort vår bröllopsdag ännu mer minnesvärd och meningsfull. Att ha er
          omkring oss för att fira vår kärlek har berikat våra hjärtan på ett sätt som vi inte kan beskriva med ord.
        </p>
        <p>
          Denna dag har varit en dröm som blivit verklighet, och er närvaro har gjort den ännu mer magisk. Att få
          omringas av sådana underbara människor som älskar och stödjer oss är något vi aldrig kommer att glömma.
        </p>
        <p>
          Vi vill också tacka för de fina gåvor och vänliga ord vi har mottagit. Det betyder mycket för oss att ni har
          tänkt på oss och delat vår glädje.
        </p>
        <p>
          Må våra gemensamma minnen från denna dag fortsätta att lysa i våra hjärtan och påminna oss om den kärlek och
          gemenskap vi känner gentemot er alla.
        </p>
        <p>
          Återigen, tack för att ni var med och förevigade denna speciella dag. Vi ser fram emot att skapa fler minnen
          tillsammans i framtiden.
        </p>
        <br />
        <p>
          Med all vår tacksamhet och kärlek,
          <br />
          Herr & Fru Gustavsson
        </p>
      </div>
    </>
  );
}
