import { Link, Route, Routes } from "react-router-dom";

import "./assets/styles/main.css";
import ImgBranch from "./assets/images/branch.png";

import { Married } from "./views/Married";

function App() {
  return (
    <>
      <div className="borderbranch">
        <div className="border1"></div>
        <img src={ImgBranch} alt="B" className="imgbranch1" />
        <img src={ImgBranch} alt="B" className="imgbranch2" />
      </div>
      {/* <Hamburger navIsToggled={navIsToggled} setNavIsToggled={(val: boolean) => setNavIsToggled(val)} />
      {navIsToggled && <Nav setNavIsToggled={(val: boolean) => setNavIsToggled(val)} />} */}
      <main>
        {/* <NavPc /> */}
        <Routes>
          <Route path="/" element={<Married />} />
          <Route path="/hem" element={<Married />} />
          {/* <Route path="/info" element={<Information />} />
          <Route path="/boende" element={<Accommodation />} />
          <Route path="/vagbeskrivning" element={<Road />} />
          <Route path="/anmalan" element={<Registration />} />
          <Route path="/onskelista" element={<Wishlist />} /> */}
          <Route
            path="*"
            element={
              <h3 style={{ margin: "3rem 0 3rem 0" }}>
                Detta är en ogiltig länk.{" "}
                <Link to="/hem">
                  <p style={{ textDecoration: "underline" }}>Till start sidan.</p>
                </Link>
              </h3>
            }
          />
        </Routes>
      </main>
    </>
  );
}

export default App;
